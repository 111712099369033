import { gql } from '@apollo/client';

export const CLIENT_FRAGMENT_FOR_COLLECTION_MODAL = gql`
    fragment ClientFragmentForCollectionModal on Client {
        id
        title
        legacyId
        collectionLimits {
            current
            max
        }
    }
`;
