/* eslint-disable max-lines-per-function */
import { gql, useMutation } from '@apollo/client';
import {
    ClientPicker,
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControl,
    DSFormControlLabel,
    DSGrid,
    DSPaper,
    DSRadio,
    DSRadioGroup,
    DSTextField,
    DSTypography,
    ErrorMessage,
    Loading,
    LocationPicker,
    SelectedClientsOrLocations,
    Translate,
} from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import { DEFAULT_CLIENT_COLLECTION_LIMIT, DEFAULT_LOCATION_COLLECTION_LIMIT, t } from '@deltasierra/shared';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { FC, forwardRef, useCallback, useMemo, useState } from 'react';
import { CreateCollectionInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useCurrentAssetContext } from '../../contexts';
import { useAllClientsAndLocations } from '../../hooks/useAllClientsAndLocations';
import { useCurrentLocationCollectionInfo } from '../../hooks/useCurrentLocationCollectionInfo';
import { useUserClientsCollectionInfo } from '../../hooks/useUserClientsCollectionInfo';
import { useUserLocationsCollectionInfo } from '../../hooks/useUserLocationsCollectionInfo';
import {
    CreateCollectionForCreateCollectionModal,
    CreateCollectionForCreateCollectionModalVariables,
} from './__graphqlTypes/CreateCollectionForCreateCollectionModal';
import { NewCollection } from './__graphqlTypes/NewCollection';
import { InitialValues, useHandleValidation } from './useHandleValidation';

const CREATE_COLLECTION_MUTATION = gql`
    mutation CreateCollectionForCreateCollectionModal($input: CreateCollectionInput!) {
        createCollection(input: $input) {
            __typename
            ... on Collection {
                id
                title
                sharedWith {
                    __typename
                }
            }
        }
    }
`;

export type CreateCollectionModalProps = {
    show: boolean;
    onClose: () => void;
};

export const CreateCollectionModal: FC<CreateCollectionModalProps> = ({ onClose, show }) => (
    <DSDialog fullWidth maxWidth="md" open={show} onClose={onClose}>
        <DSDialogTitle>{t('COMMON.NEW_COLLECTION')}</DSDialogTitle>
        {show && <CreateCollectionModalContent onClose={onClose} />}
    </DSDialog>
);
CreateCollectionModal.displayName = 'CreateCollectionModal';

type CreateCollectionModalContentProps = {
    onClose: () => void;
};

// OptTitleField - an 'optimized' form field that debounces sending the change event to formik
// Explain: No one likes this hack less than me, but for now, the rerenders formik was causing when you type
// A title into the text field was slowing down the UI.  We really cannot slow down typing because it is
// Very obvious.  This hack is to debounce sending the title to formik using a 'search bar'.
// TODO: embiggen the performance on this page
type OptTitleProps = { error?: boolean; helperText?: string; onChange: (val: string) => void };

const OptTitleField = forwardRef<HTMLInputElement, OptTitleProps>((props, ref) => {
    const { inputProps } = useControlledSearchInput({ initialValue: '', onSearchTermValueChange: props.onChange });

    return (
        <DSTextField
            autoFocus
            data-cy="collection-title-input"
            error={props.error}
            fullWidth
            helperText={props.helperText}
            inputRef={ref}
            placeholder="Name"
            variant="outlined"
            {...inputProps}
        />
    );
});
OptTitleField.displayName = 'OptTitleField';

const CreateCollectionModalContent: FC<CreateCollectionModalContentProps> = ({ onClose }) => {
    const notifier = useAngularServiceContext('mvNotifier');

    const [, setCurrentAsset] = useCurrentAssetContext();
    const [searchFilter, setSearchFilter] = useState<string | undefined>('');

    const [createCollection] = useMutation<
        CreateCollectionForCreateCollectionModal,
        CreateCollectionForCreateCollectionModalVariables
    >(CREATE_COLLECTION_MUTATION, {
        // This is pretty intense logic for a component...\
        // Basically updating the cache manually so we don't need to requery for collections
        update(cache, { data: createCollectionData }) {
            if (createCollectionData?.createCollection.__typename === 'Collection') {
                const clientOrCollection = { __typename: 'Location', id: currentLocation?.id };
                if (createCollectionData.createCollection.sharedWith.__typename === 'SharedWithClients') {
                    cache.modify<{ clientCollections: any }>({
                        fields: {
                            clientCollections: (
                                existingRef: { edges: [{ node: { id: string; title: string } }] },
                                { readField },
                            ): any => {
                                if (createCollectionData.createCollection.__typename === 'Collection') {
                                    const newCollectionRef = cache.writeFragment<NewCollection>({
                                        data: createCollectionData.createCollection,
                                        fragment: gql`
                                            fragment NewCollection on Collection {
                                                id
                                                title
                                            }
                                        `,
                                    });
                                    if (
                                        existingRef.edges
                                            .map(({ node }) => node)
                                            .some(
                                                (ref: { id: string }) =>
                                                    createCollectionData.createCollection.__typename === 'Collection' &&
                                                    readField('id', ref) === createCollectionData.createCollection.id,
                                            )
                                    ) {
                                        return existingRef;
                                    }
                                    return {
                                        ...existingRef,
                                        edges: [...existingRef.edges, { node: newCollectionRef }],
                                    };
                                }
                                return existingRef;
                            },
                        },
                        id: cache.identify(clientOrCollection),
                    });
                } else {
                    cache.modify<{ collections: any }>({
                        fields: {
                            collections(
                                existingRef: { edges: [{ node: { id: string; title: string } }] },
                                { readField },
                            ): any {
                                if (createCollectionData.createCollection.__typename === 'Collection') {
                                    const newCollectionRef = cache.writeFragment<NewCollection>({
                                        data: createCollectionData.createCollection,
                                        fragment: gql`
                                            fragment NewCollection on Collection {
                                                id
                                                title
                                            }
                                        `,
                                    });
                                    if (
                                        existingRef.edges
                                            .map(({ node }) => node)
                                            .some(
                                                (ref: { id: string }) =>
                                                    createCollectionData.createCollection.__typename === 'Collection' &&
                                                    readField('id', ref) === createCollectionData.createCollection.id,
                                            )
                                    ) {
                                        return existingRef;
                                    }
                                    return {
                                        ...existingRef,
                                        edges: [...existingRef.edges, { node: newCollectionRef }],
                                    };
                                }
                                return existingRef;
                            },
                        },
                        id: cache.identify(clientOrCollection),
                    });
                }
            }
        },
    });

    const { canCreateLocationAndClientCollection, currentClient, currentInfoLoading, currentLocation } =
        useCurrentLocationCollectionInfo();
    const { locations, locationsLoading } = useUserLocationsCollectionInfo();
    const { clients, clientsLoading } = useUserClientsCollectionInfo({
        canCreateLocationAndClientCollection,
        searchFilter,
    });
    const { allClients, allLocations, clientsWithLocations } = useAllClientsAndLocations({
        clients,
        currentClient,
        currentLocation,
        locations,
    });

    const initialValues: InitialValues = useMemo(
        () => ({
            currentClient: currentClient
                ? {
                      currentNumCollections: currentClient.collectionLimits.current,
                      maxNumCollections: currentClient.collectionLimits.max,
                      title: currentClient.title,
                  }
                : {
                      currentNumCollections: 0,
                      maxNumCollections: DEFAULT_CLIENT_COLLECTION_LIMIT,
                      title: '',
                  },
            currentLocation: currentLocation
                ? {
                      currentNumCollections: currentLocation.collectionLimits.current,
                      maxNumCollections: currentLocation.collectionLimits.max,
                      title: currentLocation.title,
                  }
                : {
                      currentNumCollections: 0,
                      maxNumCollections: DEFAULT_LOCATION_COLLECTION_LIMIT,
                      title: '',
                  },
            modelType: canCreateLocationAndClientCollection ? ('client' as const) : ('location' as const),
            selectedClientIds: currentClient ? [currentClient.id] : [],
            selectedLocationIds: currentLocation ? [currentLocation.id] : [],
            title: '',
        }),
        [canCreateLocationAndClientCollection, currentClient, currentLocation],
    );

    const isLocationsPageLoading = currentInfoLoading || locationsLoading;
    const isClientsPageLoading = currentInfoLoading || clientsLoading;

    const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
        formikHelpers.setSubmitting(true);
        const input: CreateCollectionInput = {
            permissionLevel: values.modelType,
            sharedWithIds: values.modelType === 'client' ? values.selectedClientIds : values.selectedLocationIds,
            title: values.title.trim(),
        };

        const { data: createCollectionResponse } = await createCollection({
            variables: {
                input,
            },
        });
        if (createCollectionResponse?.createCollection.__typename === 'Collection') {
            setCurrentAsset(createCollectionResponse.createCollection.id);
        } else {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_CREATE_COLLECTION') }));
            formikHelpers.setSubmitting(false);
        }

        onClose();
        return Promise.resolve();
    };

    const onTitleFieldChange = useCallback((value: string, formik: FormikProps<typeof initialValues>) => {
        formik.setFieldValue('title', value);
        formik.setTouched({ title: true });
    }, []);

    const handleValidation = useHandleValidation();

    // This prevents a formik issue, it has to be here
    if (!currentLocation || !currentClient) {
        return <Loading />;
    }

    return (
        <Formik<typeof initialValues>
            initialValues={initialValues}
            validate={handleValidation}
            validateOnMount
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <Form>
                        <DSDialogContent>
                            <DSGrid container direction="column">
                                <DSGrid
                                    alignItems="center"
                                    container
                                    direction="row"
                                    item
                                    style={{ margin: '4px 0px' }}
                                >
                                    <DSGrid item justifyContent="flex-end" md={2} sm={3} xs={12}>
                                        <DSTypography component="p" variant="h6">
                                            {t('COMMON.NAME')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <OptTitleField
                                            error={formik.touched.title && !!formik.errors.title}
                                            helperText={formik.touched.title ? formik.errors.title : ''}
                                            onChange={value => onTitleFieldChange(value, formik)}
                                        />
                                    </DSGrid>
                                </DSGrid>
                                {canCreateLocationAndClientCollection && (
                                    <DSGrid
                                        alignItems="center"
                                        container
                                        direction="row"
                                        item
                                        style={{ margin: '4px 0px' }}
                                    >
                                        <DSGrid item justifyContent="flex-end" md={2} sm={3} xs={12}>
                                            <DSTypography component="p" variant="h6">
                                                <Translate keyId="ASSET_LIBRARY.SHARED_WITH" />
                                            </DSTypography>
                                        </DSGrid>
                                        <DSGrid item md={10} sm={9} xs={12}>
                                            <DSFormControl>
                                                <DSRadioGroup
                                                    row
                                                    value={formik.values.modelType}
                                                    onChange={value =>
                                                        formik.setFieldValue('modelType', value.target.value)
                                                    }
                                                >
                                                    <DSFormControlLabel
                                                        control={<DSRadio />}
                                                        label={t('COMMON.CLIENTS')}
                                                        value="client"
                                                    />
                                                    <DSFormControlLabel
                                                        control={<DSRadio />}
                                                        data-cy="location-radio-button"
                                                        label={t('COMMON.LOCATIONS')}
                                                        value="location"
                                                    />
                                                </DSRadioGroup>
                                            </DSFormControl>
                                        </DSGrid>
                                    </DSGrid>
                                )}
                                <DSGrid container direction="row" item style={{ margin: '4px 0px' }}>
                                    <DSGrid
                                        item
                                        justifyContent="flex-end"
                                        md={2}
                                        sm={3}
                                        style={{ margin: '14px 0px' }}
                                        xs={12}
                                    >
                                        <DSTypography component="p" variant="h6">
                                            {formik.values.modelType === 'client'
                                                ? t('COMMON.CLIENTS')
                                                : t('COMMON.LOCATIONS')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                border: '1px solid #eee',
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            {formik.values.modelType === 'client' ? (
                                                <>
                                                    <ClientPicker
                                                        clients={clientsWithLocations}
                                                        disabled={currentClient ? [currentClient.id] : undefined}
                                                        selected={formik.values.selectedClientIds}
                                                        onChange={value =>
                                                            formik.setFieldValue('selectedClientIds', value)
                                                        }
                                                        onSearchTermChange={setSearchFilter}
                                                    />
                                                    {isClientsPageLoading && <Loading />}
                                                </>
                                            ) : (
                                                <LocationPicker
                                                    clients={clientsWithLocations}
                                                    disabled={currentLocation ? [currentLocation.id] : undefined}
                                                    loading={isLocationsPageLoading}
                                                    selected={formik.values.selectedLocationIds}
                                                    onChange={value =>
                                                        formik.setFieldValue('selectedLocationIds', value)
                                                    }
                                                />
                                            )}
                                        </DSPaper>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            <SelectedClientsOrLocations
                                                items={
                                                    formik.values.modelType === 'client'
                                                        ? allClients.filter(
                                                              client =>
                                                                  formik.values.selectedClientIds.indexOf(client.id) >=
                                                                  0,
                                                          )
                                                        : allLocations.filter(
                                                              location =>
                                                                  formik.values.selectedLocationIds.indexOf(
                                                                      location.id,
                                                                  ) >= 0,
                                                          )
                                                }
                                                title={
                                                    formik.values.modelType === 'client'
                                                        ? t('ASSET_LIBRARY.SELECTED_CLIENTS')
                                                        : t('ASSET_LIBRARY.SELECTED_LOCATIONS')
                                                }
                                            />
                                            {(formik.values.modelType === 'client'
                                                ? isClientsPageLoading
                                                : isLocationsPageLoading) && <Loading />}
                                        </DSPaper>
                                    </DSGrid>
                                </DSGrid>
                            </DSGrid>
                            {formik.errors?.currentClient?.currentNumCollections && (
                                <ErrorMessage message={formik.errors.currentClient.currentNumCollections} />
                            )}
                            {formik.errors?.currentLocation?.currentNumCollections && (
                                <ErrorMessage message={formik.errors.currentLocation.currentNumCollections} />
                            )}
                        </DSDialogContent>
                        <DSDialogActions>
                            <DSButton
                                color="default"
                                disabled={formik.isSubmitting}
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t('COMMON.CANCEL')}
                            </DSButton>
                            <DSButton
                                color="success"
                                data-cy="create-collection-button"
                                disabled={!formik.isValid || formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                {t('COMMON.CREATE')}
                            </DSButton>
                        </DSDialogActions>
                    </Form>
                </>
            )}
        </Formik>
    );
};
CreateCollectionModalContent.displayName = 'CreateCollectionModalContent';
