import { filterUniqueBy } from '@deltasierra/array-utilities';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { useMemo } from 'react';
import { AssetShareTarget, AssetShareTargetGroup } from '@deltasierra/components';
import { useUserLocationsCollectionInfo } from './useUserLocationsCollectionInfo';
import { useCurrentLocationCollectionInfo } from './useCurrentLocationCollectionInfo';
import { useUserClientsCollectionInfo } from './useUserClientsCollectionInfo';

type UseAllClientsAndLocationsOptions = {
    clients: ReturnType<typeof useUserClientsCollectionInfo>['clients'];
    currentClient: ReturnType<typeof useCurrentLocationCollectionInfo>['currentClient'];
    currentLocation: ReturnType<typeof useCurrentLocationCollectionInfo>['currentLocation'];
    locations: ReturnType<typeof useUserLocationsCollectionInfo>['locations'];
};

export function useAllClientsAndLocations({
    clients,
    currentClient,
    currentLocation,
    locations,
}: UseAllClientsAndLocationsOptions): {
    allLocations: ReturnType<typeof useUserLocationsCollectionInfo>['locations'];
    allClients: ReturnType<typeof useUserClientsCollectionInfo>['clients'];
    clientsWithLocations: Array<AssetShareTarget & AssetShareTargetGroup>;
} {
    const allLocations = useMemo(
        () => [currentLocation, ...locations].filter(isNotNullOrUndefined).filter(filterUniqueBy(item => item.id)),
        [currentLocation, locations],
    );
    const allClients = useMemo(
        () => [currentClient, ...clients].filter(isNotNullOrUndefined).filter(filterUniqueBy(item => item.id)),
        [clients, currentClient],
    );

    const clientsWithLocations: Array<AssetShareTarget & AssetShareTargetGroup> = useMemo(
        () =>
            allClients.map((client): AssetShareTarget & AssetShareTargetGroup => ({
                currentCollections: client.collectionLimits.current,
                id: client.id,
                items: allLocations
                    .filter(location => location.client.id === client.id)
                    .map(
                        (location): AssetShareTarget => ({
                            currentCollections: location.collectionLimits.current,
                            id: location.id,
                            maxCollections: location.collectionLimits.max,
                            title: location.title,
                        }),
                    ),
                maxCollections: client.collectionLimits.max,
                title: client.title,
            })),
        [allClients, allLocations],
    );

    return {
        allClients,
        allLocations,
        clientsWithLocations,
    };
}
