import React from 'react';
import { LeftNavigationTopBar } from '@deltasierra/components';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { OptionalOneWayBinding } from '../../common/angularData';

export type LeftNavigationTopBarAngularWrapperProps = {
    isLeftNavbarEnabled: boolean;
};

export function LeftNavigationTopBarAngularWrapper({
    isLeftNavbarEnabled,
}: LeftNavigationTopBarAngularWrapperProps): JSX.Element {
    return (
        <>
            {isLeftNavbarEnabled && (
                <LeftNavigationTopBar
                    helpUrl={''}
                    isOpenDrawer={true}
                    logoImageSrc={'../../../img/DS_Horizontal Logo_RGB.svg'}
                    notifications={[]}
                    user={{
                        initials: 'LS',
                    }}
                    onClickHamburger={() => undefined}
                />
            )}
        </>
    );
}

LeftNavigationTopBarAngularWrapper.displayName = 'LeftNavigationTopBarAngularWrapper';

export const LeftNavigationTopBarAngularWrapperWithAngular = withAngularIntegration(
    LeftNavigationTopBarAngularWrapper,
    'leftNavigationTopBarAngularWrapper',
    {
        isLeftNavbarEnabled: OptionalOneWayBinding,
    },
);
