import { gql, useQuery } from '@apollo/client';
import { CLIENT_FRAGMENT_FOR_COLLECTION_MODAL } from './CollectionModalClient.fragment';
import { ClientFragmentForCollectionModal } from './__graphqlTypes/ClientFragmentForCollectionModal';
import {
    GetCurrentLocationForCreateCollectionModal,
    GetCurrentLocationForCreateCollectionModal_location,
} from './__graphqlTypes/GetCurrentLocationForCreateCollectionModal';

const GET_CURRENT_LOCATION_FOR_CREATE_COLLECTION_MODAL_QUERY = gql`
    query GetCurrentLocationForCreateCollectionModal($locationId: ID!, $hasLocation: Boolean! = false) {
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            title
            canCreateCollection
            client {
                id
                canCreateCollection
                ...ClientFragmentForCollectionModal
            }
            collectionLimits {
                current
                max
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_COLLECTION_MODAL}
`;

export function useCurrentLocationCollectionInfo(): {
    canCreateLocationAndClientCollection: boolean;
    currentClient: ClientFragmentForCollectionModal | null;
    currentInfoLoading: boolean;
    // eslint-disable-next-line camelcase
    currentLocation: GetCurrentLocationForCreateCollectionModal_location | null;
} {
    const { data, loading } = useQuery<GetCurrentLocationForCreateCollectionModal>(
        GET_CURRENT_LOCATION_FOR_CREATE_COLLECTION_MODAL_QUERY,
    );

    const canCreateLocationAndClientCollection = !!(
        data?.location?.canCreateCollection && data?.location?.client.canCreateCollection
    );

    return {
        canCreateLocationAndClientCollection,
        currentClient: data?.location?.client ?? null,
        currentInfoLoading: loading,
        currentLocation: data?.location ?? null,
    };
}
