import { gql } from '@apollo/client';

export const LOCATION_FRAGMENT_FOR_COLLECTION_MODAL = gql`
    fragment LocationFragmentForCollectionModal on Location {
        id
        title
        client {
            id
        }
        collectionLimits {
            current
            max
        }
    }
`;
