/* eslint-disable camelcase */
import { gql, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useConnectionFetchMore } from '../../graphql/hooks';
import { relayConnectionToArray } from '../../graphql/utils';
import { LOCATION_FRAGMENT_FOR_COLLECTION_MODAL } from './CollectionModalLocation.fragment';
import {
    GetUserLocationsForCreateCollectionModal,
    GetUserLocationsForCreateCollectionModalVariables,
    GetUserLocationsForCreateCollectionModal_me_locations_edges_node,
} from './__graphqlTypes/GetUserLocationsForCreateCollectionModal';

const GET_USER_LOCATIONS_FOR_CREATE_COLLECTION_MODAL_QUERY = gql`
    query GetUserLocationsForCreateCollectionModal($after: String, $first: Int) {
        me {
            id
            locations(first: $first, after: $after) {
                edges {
                    node {
                        id
                        ...LocationFragmentForCollectionModal
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
    ${LOCATION_FRAGMENT_FOR_COLLECTION_MODAL}
`;

export function useUserLocationsCollectionInfo(): {
    locations: GetUserLocationsForCreateCollectionModal_me_locations_edges_node[];
    locationsLoading: boolean;
} {
    const { data, fetchMore, loading } = useQuery<
        GetUserLocationsForCreateCollectionModal,
        GetUserLocationsForCreateCollectionModalVariables
    >(GET_USER_LOCATIONS_FOR_CREATE_COLLECTION_MODAL_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
            first: 3000,
        },
    });

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.me.locations, async after =>
        fetchMore?.({ variables: { after } }),
    );

    const locations = useMemo(() => relayConnectionToArray(data?.me.locations), [data?.me.locations]);

    // This loops and fetches all the users locations
    useEffect(() => {
        if (hasNextPage && !loading) {
            handleFetchMore();
        }
    }, [handleFetchMore, hasNextPage, loading]);

    return {
        locations,
        locationsLoading: loading || hasNextPage,
    };
}
