import { MvNavbar } from '../account/mvNavbar';
import { $scopeSID } from '../common/angularData';

/**
 * Assigns navbar to the current scope.
 * Can be used in conjunction with "ng-if" to provide behaviour based on navbar configuration.
 *
 * @param mvNavbar
 * @returns An angular directive
 */
angular.module('app').directive('dsNavbar', [MvNavbar.SID, function (mvNavbar: MvNavbar) {
    interface DsNavbarScope extends ng.IScope {
        navbar: MvNavbar;
    }

    return {
        restrict: 'EAM',
        priority: 700, // Must be higher than ng-if, which is 600
        controller: [
            $scopeSID,
            function ($scope: DsNavbarScope) {
                $scope.navbar = mvNavbar;
            },
        ],
    };
}]);
