/* eslint-disable max-lines-per-function */
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    ClientPicker,
    DSButton,
    DSConfirmButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSGrid,
    DSPaper,
    DSTextField,
    DSTypography,
    Loading,
    LocationPicker,
    SelectedClientsOrLocations,
} from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { FC, forwardRef, useMemo, useState } from 'react';
import { DeleteCollectionsInput, EditCollectionInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { relayConnectionToArray } from '../../../graphql/utils';
import { useCurrentAssetContext } from '../../contexts';
import { CLIENT_FRAGMENT_FOR_COLLECTION_MODAL } from '../../hooks/CollectionModalClient.fragment';
import { LOCATION_FRAGMENT_FOR_COLLECTION_MODAL } from '../../hooks/CollectionModalLocation.fragment';
import { useAllClientsAndLocations } from '../../hooks/useAllClientsAndLocations';
import { useCurrentLocationCollectionInfo } from '../../hooks/useCurrentLocationCollectionInfo';
import { useUserClientsCollectionInfo } from '../../hooks/useUserClientsCollectionInfo';
import { useUserLocationsCollectionInfo } from '../../hooks/useUserLocationsCollectionInfo';
import {
    DeleteCollectionForEditCollectionModal,
    DeleteCollectionForEditCollectionModalVariables,
} from './__graphqlTypes/DeleteCollectionForEditCollectionModal';
import {
    EditCollectionForEditCollectionModal,
    EditCollectionForEditCollectionModalVariables,
} from './__graphqlTypes/EditCollectionForEditCollectionModal';
import {
    GetCollectionForEditCollectionModalQuery,
    GetCollectionForEditCollectionModalQueryVariables,
} from './__graphqlTypes/GetCollectionForEditCollectionModalQuery';

const GET_COLLECTION_FOR_EDIT_COLLECTION_MODAL_QUERY = gql`
    query GetCollectionForEditCollectionModalQuery($collectionId: ID!) {
        collection(id: $collectionId) {
            __typename
            ... on Collection {
                id
                title
                sharedWith {
                    __typename
                    ... on SharedWithLocations {
                        locations {
                            edges {
                                node {
                                    id
                                    ...LocationFragmentForCollectionModal
                                }
                            }
                        }
                    }
                    ... on SharedWithClients {
                        clients {
                            edges {
                                node {
                                    id
                                    ...ClientFragmentForCollectionModal
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_COLLECTION_MODAL}
    ${LOCATION_FRAGMENT_FOR_COLLECTION_MODAL}
`;

const EDIT_COLLECTION_MUTATION = gql`
    mutation EditCollectionForEditCollectionModal($input: EditCollectionInput!) {
        editCollection(input: $input) {
            __typename
            ... on Collection {
                id
                title
            }
        }
    }
`;

const DELETE_COLLECTIONS_MUTATION = gql`
    mutation DeleteCollectionForEditCollectionModal($input: DeleteCollectionsInput!) {
        deleteCollections(input: $input) {
            __typename
            ... on DeleteCollectionsPayload {
                ids
            }
        }
    }
`;

export type EditCollectionModalProps = {
    collectionId: string;
    show: boolean;
    onClose: () => void;
};

export const EditCollectionModal: FC<EditCollectionModalProps> = ({ collectionId, onClose, show }) => (
    <DSDialog fullWidth maxWidth="md" open={show} onClose={onClose}>
        <DSDialogTitle>{t('COMMON.EDIT_COLLECTION')}</DSDialogTitle>
        {show && <EditCollectionModalContent collectionId={collectionId} onClose={onClose} />}
    </DSDialog>
);
EditCollectionModal.displayName = 'EditCollectionModal';

type EditCollectionModalContentProps = {
    onClose: () => void;
    collectionId: string;
};

// OptTitleField - an 'optimized' form field that debounces sending the change event to formik
// Explain: No one likes this hack less than me, but for now, the rerenders formik was causing when you type
// A title into the text field was slowing down the UI.  We really cannot slow down typing because it is
// Very obvious.  This hack is to debounce sending the title to formik using a 'search bar'.
// TODO: embiggen the performance on this page
type OptTitleProps = { initialValue: string; onChange: (val: string) => void };

const OptTitleField = forwardRef<HTMLInputElement, OptTitleProps>((props, ref) => {
    const { inputProps } = useControlledSearchInput({
        initialValue: props.initialValue,
        onSearchTermValueChange: props.onChange,
    });

    return <DSTextField autoFocus fullWidth inputRef={ref} placeholder="Name" variant="outlined" {...inputProps} />;
});
OptTitleField.displayName = 'OptTitleField';

const EditCollectionModalContent: FC<EditCollectionModalContentProps> = ({ collectionId, onClose }) => {
    const notifier = useAngularServiceContext('mvNotifier');

    const [editCollection] = useMutation<
        EditCollectionForEditCollectionModal,
        EditCollectionForEditCollectionModalVariables
    >(EDIT_COLLECTION_MUTATION);
    const [deleteCollection] = useMutation<
        DeleteCollectionForEditCollectionModal,
        DeleteCollectionForEditCollectionModalVariables
    >(DELETE_COLLECTIONS_MUTATION);

    const [, setCurrentAsset] = useCurrentAssetContext();

    const [searchFilter, setSearchFilter] = useState<string | undefined>('');

    const { data, loading: collectionLoading } = useQuery<
        GetCollectionForEditCollectionModalQuery,
        GetCollectionForEditCollectionModalQueryVariables
    >(GET_COLLECTION_FOR_EDIT_COLLECTION_MODAL_QUERY, {
        fetchPolicy: 'no-cache',
        variables: { collectionId },
    });

    const { canCreateLocationAndClientCollection, currentClient, currentInfoLoading, currentLocation } =
        useCurrentLocationCollectionInfo();
    const { locations, locationsLoading } = useUserLocationsCollectionInfo();
    const { clients, clientsLoading } = useUserClientsCollectionInfo({
        canCreateLocationAndClientCollection,
        searchFilter,
    });
    const { allClients, allLocations, clientsWithLocations } = useAllClientsAndLocations({
        clients,
        currentClient,
        currentLocation,
        locations,
    });

    const initialValues = useMemo(
        () =>
            data?.collection.__typename === 'Collection'
                ? {
                      modelType:
                          data.collection.sharedWith.__typename === 'SharedWithLocations' ? 'location' : 'client',
                      selectedClientIds:
                          data.collection.sharedWith.__typename === 'SharedWithClients'
                              ? relayConnectionToArray(data.collection.sharedWith.clients).map(item => item.id)
                              : [],
                      selectedLocationIds:
                          data.collection.sharedWith.__typename === 'SharedWithLocations'
                              ? relayConnectionToArray(data.collection.sharedWith.locations).map(item => item.id)
                              : [],
                      title: data?.collection.__typename === 'Collection' ? data.collection.title : '',
                  }
                : {
                      modelType: 'client',
                      selectedClientIds: [],
                      selectedLocationIds: [],
                      title: '',
                  },
        [data?.collection],
    );

    const isLocationsPageLoading = currentInfoLoading || locationsLoading || collectionLoading;
    const isClientsPageLoading = currentInfoLoading || clientsLoading || collectionLoading;

    const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
        formikHelpers.setSubmitting(true);
        const input: EditCollectionInput = {
            id: collectionId,
            permissionLevel: values.modelType,
            sharedWithIds: values.modelType === 'client' ? values.selectedClientIds : values.selectedLocationIds,
            title: values.title,
        };

        const { data: createCollectionResponse } = await editCollection({
            variables: {
                input,
            },
        });
        if (createCollectionResponse?.editCollection.__typename !== 'Collection') {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_CREATE_COLLECTION') }));
            formikHelpers.setSubmitting(false);
        }

        onClose();
        return Promise.resolve();
    };

    const handleDelete = async () => {
        const input: DeleteCollectionsInput = {
            ids: [collectionId],
        };

        await deleteCollection({
            update: (cache, { data: result }) => {
                if (result?.deleteCollections.__typename === 'DeleteCollectionsPayload') {
                    const clientOrLocation =
                        initialValues.modelType === 'client'
                            ? { __typename: 'Client', id: currentClient?.id }
                            : { __typename: 'Location', id: currentLocation?.id };
                    cache.modify<{ collections: any }>({
                        fields: {
                            collections(existingRef: { edges: [{ node: { id: string; title: string } }] }): any {
                                if (result.deleteCollections.__typename === 'DeleteCollectionsPayload') {
                                    return {
                                        ...existingRef,
                                        edges: existingRef.edges.filter(
                                            ({ node }) =>
                                                result.deleteCollections.__typename === 'DeleteCollectionsPayload' &&
                                                !result.deleteCollections.ids.includes(node.id),
                                        ),
                                    };
                                }
                                return existingRef;
                            },
                        },
                        id: cache.identify(clientOrLocation),
                        optimistic: true,
                    });
                    result.deleteCollections.ids.forEach(id =>
                        cache.evict({ id: cache.identify({ __typename: 'Collection', id }) }),
                    );
                }
                cache.gc();
            },
            variables: { input },
        });
        // This kills the crab
        setTimeout(() => setCurrentAsset(undefined), 500);
    };

    const handleValidation = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<typeof initialValues> = {};
        if (!values.title) {
            errors.title = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }
        return errors;
    };

    if (!currentLocation || !currentClient || !data?.collection) {
        return <Loading />;
    }
    return (
        <Formik<typeof initialValues>
            initialValues={initialValues}
            validate={handleValidation}
            validateOnMount
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <Form>
                        <DSDialogContent>
                            <DSGrid container direction="column">
                                <DSGrid
                                    alignItems="center"
                                    container
                                    direction="row"
                                    item
                                    style={{ margin: '4px 0px' }}
                                >
                                    <DSGrid item justifyContent="flex-end" md={2} sm={3} xs={12}>
                                        <DSTypography component="p" variant="h6">
                                            {t('COMMON.NAME')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <OptTitleField
                                            initialValue={formik.values.title}
                                            onChange={value => formik.setFieldValue('title', value)}
                                        />
                                    </DSGrid>
                                </DSGrid>
                                <DSGrid container direction="row" item style={{ margin: '4px 0px' }}>
                                    <DSGrid
                                        item
                                        justifyContent="flex-end"
                                        md={2}
                                        sm={3}
                                        style={{ margin: '14px 0px' }}
                                        xs={12}
                                    >
                                        <DSTypography component="p" variant="h6">
                                            {formik.values.modelType === 'client'
                                                ? t('COMMON.CLIENTS')
                                                : t('COMMON.LOCATIONS')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                border: '1px solid #eee',
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            {formik.values.modelType === 'client' ? (
                                                <>
                                                    <ClientPicker
                                                        clients={clientsWithLocations}
                                                        disabled={currentClient ? [currentClient.id] : undefined}
                                                        selected={formik.values.selectedClientIds}
                                                        onChange={value =>
                                                            formik.setFieldValue('selectedClientIds', value)
                                                        }
                                                        onSearchTermChange={setSearchFilter}
                                                    />
                                                    {isClientsPageLoading && <Loading />}
                                                </>
                                            ) : (
                                                <LocationPicker
                                                    clients={clientsWithLocations}
                                                    disabled={currentLocation ? [currentLocation.id] : undefined}
                                                    loading={isLocationsPageLoading}
                                                    selected={formik.values.selectedLocationIds}
                                                    onChange={value =>
                                                        formik.setFieldValue('selectedLocationIds', value)
                                                    }
                                                />
                                            )}
                                        </DSPaper>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            <SelectedClientsOrLocations
                                                items={
                                                    formik.values.modelType === 'client'
                                                        ? allClients.filter(
                                                              client =>
                                                                  formik.values.selectedClientIds.indexOf(client.id) >=
                                                                  0,
                                                          )
                                                        : allLocations.filter(
                                                              location =>
                                                                  formik.values.selectedLocationIds.indexOf(
                                                                      location.id,
                                                                  ) >= 0,
                                                          )
                                                }
                                                title={
                                                    formik.values.modelType === 'client'
                                                        ? t('ASSET_LIBRARY.SELECTED_CLIENTS')
                                                        : t('ASSET_LIBRARY.SELECTED_LOCATIONS')
                                                }
                                            />
                                            {(formik.values.modelType === 'client'
                                                ? isClientsPageLoading
                                                : isLocationsPageLoading) && <Loading />}
                                        </DSPaper>
                                    </DSGrid>
                                </DSGrid>
                            </DSGrid>
                        </DSDialogContent>
                        <DSDialogActions>
                            <DSConfirmButton
                                color="error"
                                disabled={formik.isSubmitting}
                                message={`${t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COLLECTION')}  ${t(
                                    'ASSET_LIBRARY.DELETE_COLLECTION_NOTE',
                                )}`}
                                style={{ marginRight: 'auto' }}
                                variant="contained"
                                onConfirm={handleDelete}
                            >
                                {t('COMMON.DELETE')}
                            </DSConfirmButton>
                            <DSButton
                                color="default"
                                disabled={formik.isSubmitting}
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t('COMMON.CANCEL')}
                            </DSButton>
                            <DSButton
                                color="primary"
                                disabled={!formik.isValid || formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                {t('COMMON.EDIT')}
                            </DSButton>
                        </DSDialogActions>
                    </Form>
                </>
            )}
        </Formik>
    );
};
EditCollectionModalContent.displayName = 'CreateCollectionModalContent';
