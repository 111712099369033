import { DSAlert, Translate } from '@deltasierra/components';
import * as React from 'react';
import { OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';

export type TrimAndBleedDisclaimerProps = {
    show: boolean;
};

const TrimAndBleedDisclaimer = ({ show }: TrimAndBleedDisclaimerProps): JSX.Element => (
    <>
        {show && (
            <DSAlert severity="info">
                <Translate keyId="BUILD.PUBLISH.INCLUDE_BLEED_AND_TRIM_MARKS_DISCLAIMER" />
            </DSAlert>
        )}
    </>
);

TrimAndBleedDisclaimer.displayName = 'TrimAndBleedDisclaimer';

export default withAngularIntegration(TrimAndBleedDisclaimer, 'trimAndBleedDisclaimer', {
    show: OneWayBinding,
});
