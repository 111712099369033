import { gql, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useConnectionFetchMore } from '../../graphql/hooks';
import { relayConnectionToArray } from '../../graphql/utils';
import { CLIENT_FRAGMENT_FOR_COLLECTION_MODAL } from './CollectionModalClient.fragment';
import { ClientFragmentForCollectionModal } from './__graphqlTypes/ClientFragmentForCollectionModal';
import {
    GetClientsForCreateCollectionModal,
    GetClientsForCreateCollectionModalVariables,
} from './__graphqlTypes/GetClientsForCreateCollectionModal';

const GET_CLIENTS_FOR_CREATE_COLLECTION_MODAL_QUERY = gql`
    query GetClientsForCreateCollectionModal($after: String, $first: Int, $title: String) {
        me {
            id
            agency {
                id
                clients(after: $after, first: $first, filter: { title: $title }) {
                    edges {
                        node {
                            id
                            ...ClientFragmentForCollectionModal
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_COLLECTION_MODAL}
`;

type UseUserClientsCollectionInfoOptions = {
    canCreateLocationAndClientCollection: boolean;
    searchFilter: string | undefined;
};

export function useUserClientsCollectionInfo({
    canCreateLocationAndClientCollection,
    searchFilter,
}: UseUserClientsCollectionInfoOptions): {
    clients: ClientFragmentForCollectionModal[];
    clientsLoading: boolean;
} {
    const { data, fetchMore, loading } = useQuery<
        GetClientsForCreateCollectionModal,
        GetClientsForCreateCollectionModalVariables
    >(GET_CLIENTS_FOR_CREATE_COLLECTION_MODAL_QUERY, {
        notifyOnNetworkStatusChange: true,
        /**
         * This here mimics the existing functionality after I have refactored this component.
         * It's a bit weird, but this skip here prevents location users from seeing any locations
         * that aren't attached to the current selected locations client. I don't know why this functionality
         * exists whether it was just bad code or by design so I am not going to change it.
         */
        skip: !canCreateLocationAndClientCollection,
        variables: {
            first: 100,
            // This filter doesn't actually
            title: searchFilter ?? '',
        },
    });

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.me.agency.clients, async after =>
        fetchMore?.({ variables: { after } }),
    );

    const clients = useMemo(() => relayConnectionToArray(data?.me.agency.clients), [data?.me.agency.clients]);

    // This loops and fetches all the clients
    useEffect(() => {
        if (hasNextPage && !loading) {
            handleFetchMore();
        }
    }, [hasNextPage, handleFetchMore, loading]);

    return {
        clients,
        clientsLoading: loading || hasNextPage,
    };
}
