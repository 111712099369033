import { GraphqlService } from '../graphql/GraphqlService';
import { MvIdentity } from './mvIdentity';
import { GET_NAVBAR_FEATURE_FLAG_QUERY } from './graphql/get-navbar-feature-flag.query';
import { GetNavbarFeatureFlag } from './graphql/__graphqlTypes/GetNavbarFeatureFlag';

export class MvNavbar {
    public static readonly SID = 'mvNavbar';

    public static readonly $inject: string[] = ['$rootScope', MvIdentity.SID, GraphqlService.SID];

    public isLeftNavbarEnabled = false;

    public constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly mvIdentity: MvIdentity,
        private readonly graphqlService: GraphqlService,
    ) { }

    public init(): void {
        this.$rootScope.$watch(() => this.mvIdentity.isAuthenticated(), async () => {
            await this.updateIsLeftNavbarEnabled();
        });
    }

    private async updateIsLeftNavbarEnabled(): Promise<void> {
        if (this.mvIdentity.isAuthenticated()) {
            const { data } = await this.graphqlService
                .getClient()
                .query<GetNavbarFeatureFlag>({
                    fetchPolicy: 'network-only',
                    query: GET_NAVBAR_FEATURE_FLAG_QUERY,
                });
            this.isLeftNavbarEnabled = data.me.features.leftHandNavigation;
        }
    }
}

angular.module('app').service(MvNavbar.SID, MvNavbar);
