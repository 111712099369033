/// <reference path="../../typings/browser.d.ts" />
import type { ILocationService, IRootScopeService } from 'angular';
import { MvIdentity } from './account/mvIdentity';
import { BootstrapBreakpointService } from './common/bootstrapBreakpointService';
import { SentryService } from './common/sentryService';
import { I18nService } from './i18n';
import { SocketService } from './sockets/socketService';
import { HotjarService } from './common/hotjarService';
import { MvNavbar } from './account/mvNavbar';

// Run means it is executed after the module is configured. i.e. after the above
angular.module('app').run([
    '$q',
    '$window',
    '$rootScope',
    '$location',
    SentryService.SID,
    SocketService.SID,
    MvIdentity.SID,
    I18nService.SID,
    BootstrapBreakpointService.SID,
    HotjarService.SID,
    MvNavbar.SID,
    // eslint-disable-next-line func-names, max-params
    async function (
        $q: ng.IQService,
        $window: Window,
        $rootScope: IRootScopeService,
        $location: ILocationService,
        sentryService: SentryService,
        socketService: SocketService,
        mvIdentity: MvIdentity,
        i18n: I18nService,
        bootstrapBreakpointService: BootstrapBreakpointService,
        hotjarService: HotjarService,
        mvNavbar: MvNavbar,
    ) {
        // Support for older browsers that don't have Promise on window scope
        (window as any).Promise = $q;

        bootstrapBreakpointService.augmentRootScope();
        hotjarService.init();

        function redirectToLogin() {
            if ($location.path() !== '/login') {
                const redirPath = encodeURIComponent($location.url());
                window.location.assign(`/login?r=${redirPath}`);
            }
        }

        $rootScope.$on('$routeChangeError', (evt, current, previous, rejection) => {
            if (!!rejection && rejection.message === 'Not authorised') {
                if (mvIdentity.isAuthenticated()) {
                    return window.location.assign('/');
                } else {
                    return redirectToLogin();
                }
            }
            return undefined;
        });

        $rootScope.watchForChange = (
            // eslint-disable-next-line @typescript-eslint/ban-types
            expression: Function,
            callback: (newValue?: any, oldValue?: any) => void,
            // eslint-disable-next-line @typescript-eslint/ban-types
        ): Function =>
            $rootScope.$watch(
                () => expression(),
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        callback(newValue, oldValue);
                    }
                },
            );

        // Const host = location.origin.replace(/^http/, 'ws');
        // Socket = Primus.connect(host);
        // SocketListeners = new Array();

        // Force dropdown menu to hide on mobile devices on page change
        function hideDropdownMenu() {
            $('.navbar-collapse').removeClass('in');
        }
        $rootScope.$on('$locationChangeStart', event => {
            // For (let i = 0; i < socketListeners.length; i++) {
            // Socket.removeAllListeners(socketListeners[i]);
            // SocketListeners = new Array();
            // }
            hideDropdownMenu();
        });

        function scrollToTop() {
            window.scrollTo(0, 0);
        }

        $rootScope.$on('$routeChangeSuccess', event => {
            scrollToTop(); // TODO: don't scroll to top if anchor point is specified
        });

        $rootScope.$on('unauthenticated', redirectToLogin);
        sentryService.setUserContext();
        await socketService.initialise();

        mvNavbar.init();
    },
]);

export default {};
